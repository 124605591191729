import * as React from 'react'
import BaseLayout from '../components/base-layout'

const PageNotFound = () => {
    return (
        <BaseLayout pageTitle="404">
            <h1 class="display-1" style={{margin:"auto", fontWeight:"400"}}><b>Page not found!</b></h1>
        </BaseLayout>
    )
}

export default PageNotFound